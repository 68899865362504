<template>
  <div class="home">
    <el-container>
      <el-header style="height: 200px;width: 800px;display: flex;align-items: center;">
        <div
            style="height: 200px;width: 800px;display: flex;flex-flow:column;justify-content:center;lllllllllsssssalign-items: center;">
          <div class="check-source">
            <div :class="collection==='Periodical'?'check-active':'check-no-active'"
                 @click="checkSource('Periodical')">文章
            </div>
            <div :class="collection==='Magazine'?'check-active':'check-no-active'"
                 @click="checkSource('Magazine')">期刊
            </div>
          </div>
          <div class="search-input">
            <el-input @change="toSearch" placeholder="请输入关键词" v-model="word" class="input-with-select">
              <el-select v-show="collection==='Periodical'" v-model="select" slot="prepend">
                <el-option label="所有领域" value="所有领域"></el-option>
                <el-option label="题名" value="题名"></el-option>
                <el-option label="作者" value="作者"></el-option>
                <el-option label="刊名" value="刊名"></el-option>
                <el-option label="作者单位" value="作者单位"></el-option>
                <el-option label="关键词" value="关键词"></el-option>
              </el-select>
              <el-select v-show="collection==='Magazine'" v-model="select" slot="prepend">
                <el-option label="所有领域" value="所有领域"></el-option>
                <el-option label="题名" value="题名"></el-option>
              </el-select>
              <i slot="suffix" v-if="word" @click="clearWord">
                <img src="../assets/clear.png" alt="">
              </i>
              <el-button slot="append" class="btn-search" @click="toSearch('new')">
                搜索
              </el-button>
            </el-input>
          </div>
          <div
              style="margin-top: 17px;display: flex;font-size: 14px;font-family: Source Han Sans CN;
              font-weight: 400;color: #666666;width: 800px;flex-wrap: nowrap;"
              v-if="absData&&absData.length&&absData.length>0&&absData[0].values&&absData[0].values.length>0">
            <div style="width: 100px;display: flex;align-items: center;">相关检索词：</div>
            <div class="onClickSearch" style="margin-right: 15px;cursor: pointer;display: flex;flex-wrap: nowrap;
                  white-space: nowrap;align-items: center;overflow: hidden;text-overflow: ellipsis"
                 v-for="(item,index) in absData[0].values||[]">
              <span :title="item.name" @click="onClickSearch(item.name)">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </el-header>
      <el-main style="display: flex;flex-flow: column;align-items: center">
        <div
            style="background: white;flex-flow: column;display: flex;width: 1400px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
          <div
              style="font-size: 24px;margin-top:25px;margin-left: 56px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #999999;">按字母筛选 A - Z
          </div>
          <div style="margin-left: 56px;display: flex;margin-top: 27px;margin-bottom: 64px">
            <div @click="topFilters(top)" :class="checkFilter===top?'topFilterCheck':'topFilter'"
                 style=""
                 v-for="top in topFilter ">
              <span>{{ top }}</span>
            </div>
          </div>
        </div>
        <div
            style="width: 1400px;min-height: 65px;
background: #e5e5e5;">
          <div
              style="height: 65px;width: 1400px;justify-content:space-between;display:flex;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
            <div style="display: flex;
    align-items: center;">
              <div @click="topIssued('周刊')" :class="checkIssued==='周刊'?'kanCheck':'kan'" style="padding-left: 60px;">
                <span>周刊</span>
              </div>
              <div @click="topIssued('月刊')" :class="checkIssued==='月刊'?'kanCheck':'kan'">
                <span>月刊</span>
              </div>
            </div>
            <div
                style="padding-right: 60px;display: flex;align-items: center;justify-content: center;font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
              排序：<span
                style="color:#231815;cursor: pointer">相关度</span>
            </div>
          </div>
        </div>
        <div style="width: 1400px;margin-top: 10px;">
          <div id="facet-select" style="width: 650px;display: flex;white-space: nowrap;overflow: hidden;">
            <div style="height: 24px;background: #F5F5F5;margin-right: 5px;" v-if="radioName">
                <span
                    style="padding: 0 10px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">{{
                    radioName
                  }}</span>
              <img @click="deleteFact()" style="cursor:pointer;padding: 0 10px" width="10" height="9"
                   src="../assets/clear-fact.png" alt="">
            </div>
          </div>
          <div style="width: 250px;display: flex;justify-content: flex-end;">
            <div style="display: flex;cursor: pointer" v-show="filter.length>0&&showSelect">
              <img @click="right" src="../assets/left.png" alt=""/>
              <img @click="left" style="margin-left:5px;" src="../assets/right.png" alt=""/>
              <div @click="clearSelect"
                   style="margin-left:5px;display:flex;align-items:center;justify-content:center;width: 40px;height: 24px;background: #F5F5F5;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color:#231815;">
                清除
              </div>
            </div>
          </div>
        </div>
        <el-skeleton v-show="totalCount>0"
                     :rows="20"
                     :style="'width:100%;min-height: 600px;'"
                     :loading="loading"
                     animated
                     :throttle="500"
        >
          <div style="display: flex;justify-content: center;">
            <div style="width:380px;background: white;" class="aside">
              <el-aside width="380px" style="width: 380px;
    background: white;
    display: flex;
    justify-content: center;">
                <el-menu :default-active="radio===''||radio===undefined?defaultCheck:radio"
                         class="el-menu-vertical-demo">
                  <el-submenu v-for="(item,index) in facetFieldDataClassCode"
                              :index="index+'_1'">
                    <template slot="title" style="padding-left: 0">
                      <span
                          style="width: 28px;height: 15px;font-size: 14px;font-family: Source Han Sans CN;
                          font-weight: bold;line-height: 24px;">
                        {{ item.name }}
                      </span>
                    </template>
                    <div v-if="item.children" v-for="(val,i) in item.children"
                         style="padding-left: 20px !important;" class="child">
                      <el-menu-item v-if="!val.children" class="facet-item"
                                    :index="val.label">
                        <el-radio v-model="radio" :label="val.label"
                                  @click.native.prevent="onRadioChange(val.label,val.name)">
                          <span>{{ val.name }}</span>
                        </el-radio>
                      </el-menu-item>
                      <el-submenu v-if="val.children" :index="val.label">
                        <template slot="title">
                          <el-radio v-model="radio" :label="val.label"
                                    @click.native.prevent="onRadioChange(val.label,val.name)">
                             <span
                                 style="width: 28px;height: 15px;font-size: 14px;font-family: Source Han Sans CN;
                          font-weight: bold;line-height: 24px;">{{ val.name }}</span>
                          </el-radio>
                        </template>
                        <el-menu-item style="padding-left: 20px !important;" class="facet-item"
                                      v-for="(val3,i3) in val.children" :index="val3.label">
                          <el-radio v-model="radio" :label="val3.label"
                                    @click.native.prevent="onRadioChange(val3.label,val3.name)">
                            <span>{{ val3.name }}</span>
                          </el-radio>
                        </el-menu-item>
                      </el-submenu>
                    </div>
                  </el-submenu>
                </el-menu>
              </el-aside>
            </div>
            <div style="    width: 1020px;
    background: white;" class="main-content">
              <div style="display: flex;align-items: center;margin-top: 10px;margin-bottom: 23px; ">
                <div style="width: 30px;display: flex;justify-content: center;">
                  <img v-show="checkList.length>0" src="../assets/clear.png" alt="" @click="clearCheck"/>
                </div>
              </div>
              <div v-for="item in data" :key="item.id">
                <el-card class="box-card">
                  <div v-show="!trans" class="text">
                    <router-link target="_blank" :to="{name:'MagazineInfo',query: {
                        collection: 'Magazine',
                        id: item.id
                      }}">
                      <div style="display: flex;align-items: center;margin-bottom: 20px;margin-top: 3px;">
                        <div
                            style="width: 775px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;"
                            v-html="item.Title"></div>
                      </div>
                    </router-link>
                  </div>
                  <div v-show="trans" class="text">
                    <router-link target="_blank" :to="{name:'MagazineInfo',query: {
                        collection: 'Magazine',
                        id: item.id
                      }}">
                      <div style="display: flex;align-items: center;margin-bottom: 20px;margin-top: 3px;">
                        <div
                            style="width: 775px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;"
                            v-html="item.Title_Translated"></div>
                      </div>
                    </router-link>
                  </div>
                </el-card>
              </div>
            </div>
          </div>
          <div class="pages"
               style="width: 1400px;display: flex;justify-content: flex-end;margin-top: 20px;margin-bottom: 40px">
            <el-pagination
                @prev-click="toPageChange(1)"
                @next-click="toPageChange(totalCount/pageSize)"
                prev-text="第一页"
                next-text="最后一页"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="pageSize"
                layout="total, prev, pager, next"
                :total="totalCount">
            </el-pagination>
          </div>
        </el-skeleton>
      </el-main>
      <Empty v-bind:keyList="absData" v-bind:word="word" v-show="!(totalCount>0)"/>
    </el-container>
  </div>
</template>

<script>
import {searchServiceClient, queryRequest, facetRequest} from "@/tools/search-api";
import merge from 'webpack-merge'
import Empty from '../components/Empty'
import region from "../../region.json"
import classCodeZh from "../../classCodeZh.json"
import classCode from "../../classCode.json";

export default {
  name: "MagazineNav",
  components: {
    Empty
  },
  data() {
    return {
      region: region,
      classCodeZh: classCodeZh,
      classCode: classCode,
      facetField: ["学科分类", "地域", "出版周期", "出版周期_Translated"],
      facetFieldData: [],
      topFilter: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      facetFieldDataClassCode: [],
      facetFieldDataZh: [],
      radio: '',
      radioName: '',
      checkFilter: '',
      checkIssued: '',
      defaultCheck: '',
      checkList: [],
      pageSize: 10,
      currentPage: 1,
      currentPageFacet: 1,
      pageSizeFacet: 5,
      filter: [],
      filterNode: [],
      word: '',
      select: '所有领域',
      collection: '',
      data: [],
      totalCount: 0,
      loading: true,
      isCheck: false,
      showSelect: false,
      sortField: "",
      absData: [],
    }
  },
  computed: {
    trans() {
      return this.$store.state.trans
    },
  },
  created() {
    let filter = this.$route.query.filter || []
    if (typeof (filter) === 'string') {
      filter = [filter]
    }
    this.currentPage = this.$route.query.currentPage || 1
    this.pageSize = this.$route.query.pageSize || 10
    this.filter = filter
    this.word = this.$route.query.word
    this.select = this.$route.query.select || '所有领域'
    if (this.select !== '所有领域' && '题名' !== this.select) {
      this.select = '所有领域'
    }
    this.collection = this.$route.query.collection || 'Magazine'
    this.sortField = this.$route.query.sortField || ''
    this.radio = this.$route.query.radio || ''
    this.checkFilter = this.$route.query.checkFilter || ''
    this.checkIssued = this.$route.query.checkIssued || ''
    this.toSearch()
  },
  updated() {
    this.showSelect = document.getElementById('facet-select').scrollWidth > 650
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to.fullPath === '/navigation') {
          location.reload()
        }
      },
      deep: true
    },
    filter(newVal, oldVal) {
      let that = this
      this.filterNode = []
      this.filter.forEach(item => {
        item = item.substr(item.indexOf(":") + 1).trim();
        if (item.indexOf('*') > 0) {
          if (item.length === 2) {
          } else if (item.length === 4) {
            try {
              that.filterNode.push(parseInt(item.substr(0, 1)))
            } catch (e) {
            }
          } else {
            try {
              that.filterNode.push(parseInt(item.substr(0, 3)))
            } catch (e) {
            }
          }
        }
      })
    },
    collection(newVal, oldVal) {
      if (this.word === '' || this.isEmpty(this.word)) {
        return
      }
      this.$router.push({
        name: newVal,
        query: {
          collection: newVal,
          select: this.select,
          word: this.word,
          currentPage: 1,
          pageSize: 10,
          filter: []
        }
      })
    }
  },
  methods: {
    topIssued(e) {
      if (this.checkIssued === e) {
        this.checkIssued = ''
      } else {
        // 不是选中，选中当前点击 Radio
        this.checkIssued = e
      }
      // 选中操作
      this.toSearch()
    },
    topFilters(e) {
      if (this.checkFilter === e) {
        this.checkFilter = ''
      } else {
        // 不是选中，选中当前点击 Radio
        this.checkFilter = e
      }
      // 选中操作
      this.toSearch()
    },
    onRadioChange(e, n) {
      // 当点击已经选中的把 activeModel 置空，就是取消选中，并返回
      if (this.radio === e) {
        this.radio = ''
        this.radioName = ''
      } else {
        // 不是选中，选中当前点击 Radio
        this.radio = e
        this.radioName = n
      }
      // 选中操作
      this.toSearch()
    },
    convert(item) {
      item = item.substr(item.indexOf(":") + 1).trim();
      if (item.indexOf('*') > 0) {
        if (item.length === 2) {
          item = item.substr(0, 1)
        } else if (item.length === 4) {
          item = item.substr(1, 2)
        } else {
          item = item.substr(1, 4)
        }
      } else {
        item = JSON.parse(item.trim())
      }
      if (this.trans) {
        return this.classCodeZh[item] || this.region[item] || JSON.parse(JSON.stringify(item))
      }
      return this.classCode[item] || this.region[item] || JSON.parse(JSON.stringify(item))
    },
    isEmpty(val) {
      if (val == null) return true;
      if (typeof val === 'boolean') return false;
      if (typeof val === 'number') return !val;
      if (val instanceof Error) return val.message === '';
      switch (Object.prototype.toString.call(val)) {
        case '[object String]':
        case '[object Array]':
          return !val.length;
        case '[object File]':
        case '[object Map]':
        case '[object Set]': {
          return !val.size;
        }
        case '[object Object]': {
          return !Object.keys(val).length;
        }
      }
      return false;
    },
    onClickSearch(word) {
      this.select = '所有领域'
      this.word = '关键词:\"' + word + '\"'
      this.toSearch('new')
    },
    sort() {
      this.sortField = this.sortField && this.sortField === 'PublishYear' ? '' : 'PublishYear'
      this.toSearch()
    },
    right() {
      document.getElementById('facet-select').scrollLeft -= 600
    },
    left() {
      document.getElementById('facet-select').scrollLeft += 600
    },
    clearSelect() {
      this.radioName = ''
      this.radio = ''
      this.toSearch()
    },
    deleteFact() {
      this.radioName = ''
      this.radio = ''
      this.toSearch()
    },
    handleCheckedFactChange(val) {
      this.currentPage = 1
      this.pageSize = 10
      this.toSearch()
    },
    clearCheck() {
      this.checkList = []
    },
    handleCheckedCitiesChange(val) {
      if (this.checkList.length > 10) {
        this.checkList = this.checkList.slice(0, 10)
        this.$message.warning("暂时只支持批量选择10篇文献")
      }
    },
    transTo() {
      this.trans = !this.trans
      this.$router.push({
        query: merge(this.$route.query,
            {
              filter: null
            }
        )
      })
      this.$router.push({
        query: merge(this.$route.query,
            {
              collection: this.collection,
              select: this.select,
              word: this.word,
              currentPage: this.currentPage,
              pageSize: this.pageSize,
              filter: this.filter,
              sortField: this.sortField,
              trans: this.trans,
              radio: this.radio,
              checkFilter: this.checkFilter,
              checkIssued: this.checkIssued
            }
        )
      })
    },
    handleCheckedAllChange(isCheck) {
      if (this.checkList.length === 10) {
        let checkList = this.checkList
        this.data.forEach(data => {
          checkList.push(data.id)
        })
        checkList = Array.from(new Set(checkList));
        if (checkList.length > 10) {
          if (checkList.length > 10) {
            this.checkList = checkList.slice(0, 10)
          }
          this.$message.warning("暂时只支持批量选择10篇文献")
          return
        }
      }
      this.isCheck = isCheck
      if (isCheck) {
        this.data.forEach(data => {
          this.checkList.push(data.id)
        })
        this.checkList = Array.from(new Set(this.checkList));
      } else {
        this.checkList = []
      }
    },
    toPageChange(val) {
      this.currentPage = Math.ceil(val)
      this.toSearch()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.toSearch()
    },
    toMore(name, num) {
      let request = facetRequest;
      request.setCurrentPage(num)
      request.setPageSize(this.pageSizeFacet)
      request.setFilterQueryList(this.filter)
      request.setDefaultField(this.select)
      request.setFacetQueryWord(this.word)
      request.setCollection(this.collection)
      request.setFacetFieldList([name])
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.facet(request, {
        ['token']: token
      }).then(res => {
        let document = res.getDocument()
        if (!document) {
          this.$message.warning("查询聚类为空")
          return
        }
        let documentJson = JSON.parse(document)
        this.facetFieldData.forEach((item, index) => {
          if (item.name === documentJson[0].name) {
            item.valueCount += documentJson[0].valueCount
            item.values = [...item.values, ...documentJson[0].values]
          }
        })
        this.facetFieldDataZh.forEach((item, index) => {
          if (item.name === documentJson[0].name) {
            item.valueCount += documentJson[0].valueCount
            item.values = [...item.values, ...documentJson[0].values]
          }
        })
      }).catch(err => {
        console.log(err)
      })
    },
    toDocument() {
    },
    facet(val) {
      let request = facetRequest;
      let filter = []
      if (val !== 'new') {
        if (!(this.radio === '' || this.radio === null || this.radio === undefined)) {
          filter.push("期刊分类:" + this.radio)
        }
        if (!(this.checkFilter === '' || this.checkFilter === null || this.checkFilter === undefined)) {
          filter.push("题名字母:" + this.checkFilter + "*")
        }
        if (!(this.checkIssued === '' || this.checkIssued === null || this.checkIssued === undefined)) {
          filter.push("出版周期:" + this.checkIssued)
        }
      } else {
        this.radio = ''
        this.checkFilter = ''
        this.checkIssued = ''
      }
      request.setCurrentPage(0)
      request.setPageSize(10000)
      request.setFilterQueryList(filter)
      request.setDefaultField(this.select)
      request.setFacetQueryWord(this.word === '' || this.word === null || this.word === undefined ? "*" : this.word)
      request.setCollection(this.collection)
      request.setFacetFieldList(["期刊分类"])
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.facetPerioClassCode(request, {
        ['token']: token
      }).then(res => {
        debugger
        let document = res.getDocument()
        if (!document) {
          this.$message.warning("查询聚类为空")
          return
        }
        let documentJson = JSON.parse(document)
        this.facetFieldDataClassCode = documentJson
        if (documentJson.length > 0) {
          let doc = documentJson[0]
          if (doc.children && doc.children.length > 0) {
            this.defaultCheck = doc.children[0].label
          }
        }
      }).catch(err => {
        console.log(err)
      })

    },
    clearWord() {
      this.word = ""
    },
    queryAbs() {
      if (this.word === '' || this.isEmpty(this.word)) {
        return
      }
      let request = facetRequest;
      request.setCurrentPage(1)
      request.setPageSize(5)
      request.setDefaultField(this.select)
      request.setFacetQueryWord(this.word)
      request.setCollection('Periodical')
      request.setFacetFieldList(['关键词'])
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.facet(request, {
        ['token']: token
      }).then(res => {
        let document = res.getDocument()
        if (!document) {
          return
        }
        this.absData = JSON.parse(document)
      }).catch(err => {
        console.log(err)
      })
    },
    checkSource(collection) {
      this.collection = collection
    },
    toSearch(val) {
      if (val === 'new') {
        this.queryAbs()
        this.filter = []
        this.currentPage = 1
        this.pageSize = 10
        this.$router.push({
          query: merge(this.$route.query,
              {
                collection: this.collection,
                select: this.select,
                word: this.word,
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                filter: null,
                sortField: null,
                radio: null,
                checkFilter: null,
                checkIssued: null
              }
          )
        })
      } else {
        this.$router.push({
          query: merge(this.$route.query,
              {
                filter: null
              }
          )
        })
        this.$router.push({
          query: merge(this.$route.query,
              {
                collection: this.collection,
                select: this.select,
                word: this.word,
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                filter: this.filter,
                sortField: this.sortField,
                radio: this.radio,
                checkFilter: this.checkFilter,
                checkIssued: this.checkIssued
              }
          )
        })
      }
      if (this.collection === 'Periodical') {
        if (this.word === null || this.word === undefined || this.word === '') {
          this.$message.warning("请输入检索式")
          return;
        }
        this.$router.push({
          name: this.collection,
          query: {
            collection: this.collection,
            select: this.select,
            word: this.word,
            currentPage: 1,
            pageSize: 10,
            filter: []
          }
        })
        return;
      }
      this.facet(val);
      let request = queryRequest
      let filter = []
      if (val !== 'new') {
        if (!(this.radio === '' || this.radio === null || this.radio === undefined)) {
          filter.push("期刊分类:" + this.radio)
        }
        if (!(this.checkFilter === '' || this.checkFilter === null || this.checkFilter === undefined)) {
          filter.push("题名字母:" + this.checkFilter + "*")
        }
        if (!(this.checkIssued === '' || this.checkIssued === null || this.checkIssued === undefined)) {
          filter.push("出版周期:" + this.checkIssued)
        }
      } else {
        this.radio = ''
        this.checkFilter = ''
        this.checkIssued = ''
      }
      request.setCurrentPage(this.currentPage)
      request.setPageSize(this.pageSize)
      request.setFilterQueryList(filter)
      request.setSortField(this.sortField)
      request.setDefaultField(this.select)
      request.setQueryWord(this.word === '' || this.word === null || this.word === undefined ? "*" : this.word)
      request.setCollection(this.collection)
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      this.loading = true
      searchServiceClient.query(request, {
        ['token']: token
      }).then(res => {
        this.loading = false
        let document = res.getDocument()
        if (!document) {
          this.$message.warning("查询为空")
          return
        }
        let parse = JSON.parse(document);
        this.data = parse.documents || []
        this.totalCount = parse.numFound || 0
      }).catch(err => {
        this.totalCount = 0
        this.loading = false
        console.log(err)
        this.$message.warning("查询失败")
      })
    }
  }
}
</script>
<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
<style lang="less">
.el-select-dropdown {
  .el-select-dropdown__item {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
  }

  .el-select-dropdown__item.selected {
    color: #231815;
    font-weight: 700;
  }
}
</style>
<style lang="less" scoped>
.home {
  display: flex;
  width: 100%;
  flex-flow: column;

  .topFilter {
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    text-align: center;
    width: 24px;
    height: 24px;
    margin: 0 8px;
    cursor: pointer;
    display: flex;
  }

  .topFilter:hover {
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #E83428;
    text-align: center;
    width: 24px;
    height: 24px;
    margin: 0 8px;
    cursor: pointer;
    display: flex;
  }

  .topFilterCheck {
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #E83428;
    text-align: center;
    width: 24px;
    height: 24px;
    margin: 0 8px;
    cursor: pointer;
    display: flex;
  }

  .kan {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 24px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    margin: 0 3px;
  }

  .kan:hover {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 24px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #E83428;
    margin: 0 3px;
  }

  .kanCheck {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 24px;
    font-weight: 400;
    margin: 0 3px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #E83428;
  }

  /deep/ .aside {
    .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
      -webkit-box-shadow: 0 0 2px 2px #0000;
      box-shadow: 0 0 2px 2px #0000;
    }

    .el-radio__inner {
      cursor: pointer;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      background: #F5F5F5;
      border: 1px solid #DDDDDD;
      border-radius: 50%;
    }

    .el-radio__inner::after {
      width: 9px;
      height: 9px;
      border-radius: 100%;
      background-color: #E83428;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
    }

    .el-radio__input.is-checked + .el-radio__label {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #E83428 !important;
      line-height: 56px;
    }

    .el-radio__label {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 56px;
    }

    .el-icon-arrow-down:before {
      content: url("../assets/sanjiao.png");
    }

    .el-tree-node__content {
      .el-icon-caret-right:before {
        content: url("../assets/sanjiao.png");
      }

      .el-tree-node__expand-icon.is-leaf {
        transform: translateX(1000px);
      }

      .el-tree-node__label {
        font-size: 14px;
        flex: 1;
      }

      .el-tree-node__expand-icon {
        padding: 0;
        margin-right: 12px;
        order: 1;
        transform: rotate(90deg);
      }

      .el-tree-node__expand-icon.expanded {
        transform: rotate(0deg);
      }
    }


    .el-menu {
      border: 0;
      overflow-x: hidden;
      overflow-y: hidden;
    }

    .el-menu::-webkit-scrollbar {
      width: 4px;
    }

    .el-menu::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    .el-menu::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);

    }

    .el-submenu {
      width: 250px;
    }

    .el-submenu .el-menu {
      overflow-y: auto;
      max-height: 300px;
      width: 340px;
      overflow-x: hidden;
    }

    .el-checkbox__label {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      display: flex;
      align-items: center;
    }

    .el-menu-item:hover {
      background: white;
    }

    .el-submenu__title:hover {
      background: white;
    }

    .el-submenu__title {
      padding-left: 0 !important;

      .el-submenu__icon-arrow {
        right: 0;
      }
    }

    .child {
      .el-submenu__title {
        padding-left: 0 !important;
        width: 230px;

        .el-submenu__icon-arrow {
          right: 0;
        }
      }
    }

    .el-checkbox__inner {
      width: 20px;
      height: 20px;
      background: #FFFFFF;
      border: 0px solid #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .el-checkbox__input {
      width: 20px;
      height: 20px;
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .el-checkbox__inner::after {
      background: #F5F5F5;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner::after {
      transform: rotate(45deg) scaleY(0);
    }

    .el-checkbox__input.is-checked .el-checkbox__inner {
      border-color: #231815;
      width: 9px;
      height: 9px;
      background: #231815;
    }
  }

  /deep/ .main-content {
    .box-card {
      margin-bottom: 17px;
    }

    .el-card {
      width: 930px;
      min-height: 100px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
      margin-left: 25px;

      .el-menu {
        border: 0;
      }

      .el-card__body {
        padding-top: 20px;
        padding-left: 10px;

        .el-submenu__title:hover {
          background: white;
        }

        .el-submenu__title {
          padding-left: 0 !important;
          width: 100px;

          .el-icon-arrow-down:before {
            content: url("../assets/sanjiao.png");
          }

        }

        .el-checkbox__input {
          width: 24px;
          height: 24px;
          background: #FFFFFF;
          border: 1px solid #DDDDDD;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .el-checkbox__inner {
          width: 24px;
          height: 24px;
          background: #FFFFFF;
          background: #FFFFFF;
          border: 0px solid #DDDDDD;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner::after {
          transform: rotate(45deg) scaleY(0);
        }

        .el-checkbox__input.is-checked .el-checkbox__inner {
          border-color: #231815;
          width: 14px;
          height: 14px;
          background: #231815;
        }
      }
    }
  }

  /deep/ .el-container.is-vertical {
    background: url("../assets/banner.png") no-repeat;
    background-size: 100% 202px;
    align-items: center;
    width: 100%;
  }

  /deep/ .el-header {
    padding: 0;
  }

  /deep/ .el-main {
    padding: 0;
    background-color: white;
    background-image: linear-gradient(#F5F5F5, #FFFFFF 10%);
    display: flex;
    width: 100%;
    justify-content: center;
  }

  /deep/ .el-select .el-input {
    width: 180px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .check-source {
    display: flex;
    cursor: pointer;

    .check-active {
      width: 96px;
      height: 36px;
      background: #DCDED9;
      border-radius: 5px 5px 0px 0px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #231815;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .check-no-active {
      width: 96px;
      height: 36px;
      background: rgb(220 222 217 / 0%);
      border-radius: 5px 5px 0px 0px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #231815;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  /deep/ .search-input {
    height: 51px;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;

    .el-select .el-input .el-select__caret.is-reverse {
      transform: rotateZ(180deg);
    }

    .el-select .el-input .el-select__caret {
      transform: rotateZ(0);
    }

    .btn-search {
      width: 110px;
      height: 51px;
      background: linear-gradient(-50deg, #231815, #453835);
      opacity: 0.96;
      border-radius: 0px 5px 5px 0px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
    }

    .el-input__suffix {
      display: flex;
      align-items: center;
      padding-right: 16px;
      cursor: pointer;
    }

    .el-icon-arrow-up:before {
      content: url("../assets/sanjiao.png");
    }

    .el-input-group {
      height: 51px;
    }

    .el-input-group--append .el-input__inner, .el-input-group__prepend {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 51px;
      border: 0;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
    }

    .el-input-group__prepend {
      border: 0;
      background: white;
      width: 170px;
      padding-right: 0;
      border-right: 1px solid #DDDDDD;

      .el-input__inner {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        line-height: 56px;
      }
    }
  }

  /deep/ .pages {
    .el-pagination {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;

      .el-pagination__total {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
      }

      .btn-prev {
        font-size: 14px;
      }

      .btn-next {
        font-size: 14px;
      }

      .el-pager li {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        background: #F5F5F5;
        margin: 0 5px;
      }

      .el-pager li.active {
        background: #231815;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  a {
    text-decoration: none;
  }

  .text {
    white-space: normal;
    text-align: justify;
  }

  .facet-item {
    padding-left: 0px !important;
    display: flex;
    align-items: center;
  }
}
</style>
